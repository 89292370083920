<template>
<div v-if="user">
    <q-breadcrumbs>
      <q-breadcrumbs-el icon="home" to="/home" />
      <q-breadcrumbs-el label="Account" />
    </q-breadcrumbs>


<!-- -->

<div class="text-uppercase text-h5 q-mt-md">
  Account Information
</div>

<div class="q-my-lg">
  <div class="row justify-between">
    <div class="text-h5">
      Personal Details
    </div>
    <div>
      <q-btn size="sm" color="blue" to="/account/edit">Edit Details</q-btn>
    </div>
  </div>

  <table class="table table-sm full-width">
    <tr>
      <th style="width: 160px;">Name</th>
      <td>
        {{user.first_name}}
        {{user.last_name}}
      </td>
    </tr>

    <tr>
      <th>Email</th>
      <td>
        <span :class="{'text-negative': ! user.verified, 'q-pr-sm': true}">{{user.email}}</span>
        <q-btn v-if="! user.verified" size="sm" color="negative" @click="resendConfirmation()">Resend Verification Email</q-btn>
      </td>
    </tr>

    <tr v-if="user.location">
      <th>Location</th>
      <td>
        {{user.location.city}},
        <span v-if="user.location.country_code == 'US'">{{user.location.state_code}}
        {{user.location.zipcode}}
        <br /></span>
        {{user.location.country_code}}
      </td>
    </tr>

    <tr>
      <th>Account ID</th>
      <td>{{user._id}}</td>
    </tr>

    <tr>
      <th>Password</th>
      <td>********* <q-btn size="sm" color="blue" to="/account/chpass">Set Password</q-btn></td>
    </tr>

    <tr v-if="! user.verified">
      <th>Verified</th>
      <td><q-btn v-if="! user.verified" size="sm" color="negative" @click="resendConfirmation()">Resend Verification Email</q-btn></td>
    </tr>


    <tr>
      <th>Employer</th>
      <td>
        <q-toggle v-model="showEmployerTools" dense>Show Employer and Hiring Tools</q-toggle>
      </td>
    </tr>


    <tr>
      <th>Job Candidate</th>
      <td>
        <q-toggle v-model="showJobseekerTools" dense>Show Job Candidate Profile and Resume Tools</q-toggle>
      </td>
    </tr>


  </table>
</div>

<div class="q-my-lg striped">
  <div class="row justify-between">
    <div class="text-h5">
      Account Features
    </div>
  </div>
  <div class="row">
    <div class="col-3 col-sm-2 flex items-center q-pa-sm text-weight-bold">
      Employer
    </div>
    <div class="col q-pa-sm">
      <span v-if="hasRole('organization')" class="text-positive">
        Enabled
      </span>
      <span v-else class="text-negative">
        Disabled
      </span>
      <q-btn v-if="! hasRole('organization')" size="sm" color="secondary" @click="showEmployerTools = true">Enable Employer Features</q-btn>
    </div>
  </div>
  <div class="row">
    <div class="col-3 col-sm-2 flex items-center q-pa-sm text-weight-bold">
      Job Candidate
    </div>
    <div class="col q-pa-sm">
      <span v-if="hasRole('jobseeker')" class="text-positive">
        Enabled
      </span>
      <span v-else class="text-negative">
        Disabled
      </span>
      <q-btn v-if="! hasRole('jobseeker')" size="sm" color="secondary" @click="showJobseekerTools = true">Enable Job Candidate Features</q-btn>
    </div>
  </div>

</div>

<div class="q-my-lg striped">
  <div class="row justify-between">
    <div class="text-h5">
      Newsletter Preferences
    </div>
  </div>
  <div class="row">
    <div class="col-3 col-sm-2 flex items-center q-pa-sm text-weight-bold">
      Frequency Limit
    </div>
    <div class="col q-pa-sm flex q-gutter-x-sm">
        <div v-if="user.newsletter_prefs" class="q-pa-sm">
          Email will be sent no more frequent than every {{ user.newsletter_prefs.freq_limit || '2' }} Day{{ user.newsletter_prefs.freq_limit == '1' ? '' : 's' }}.
        </div>
        <div v-else class="q-pa-sm">
          You have no limit set
        </div>

          <div>
            <q-input v-model.number="newFrequency" dense type="number" style="width: 75px;" label="Days" />
          </div>
          <div class="q-pa-sm"><q-btn size="sm" color="blue" @click="setNLFreq">Set Frequency</q-btn></div>
    </div>
  </div>
</div>

<div v-if="false" class="q-my-lg">
  <div class="row justify-between">
    <div class="text-h5">
     Newsletter Preferences
    </div>
    <div v-if="false">
      <q-btn size="sm" color="blue" to="/account/newsletter/edit">Edit Preferences</q-btn>
    </div>
  </div>

  <table v-if="user.newsletter_prefs" class="table table-sm table-striped full-width">
    <tr>
      <th style="width: 180px;">Frequency</th>
      <td>{{user.newsletter_prefs.frequency}}</td>
    </tr>

    <tr>
      <th>Countries</th>
      <td>
        <span v-if="user.newsletter_prefs.countries.length == 0">All Countries</span>
        <span v-for="sc in user.newsletter_prefs.countries" :key="sc">
            <span class="badge badge-xs badge-primary">{{sc}}</span>&nbsp;</span>
      </td>
    </tr>

    <tr>
      <th>States</th>
      <td>
        <span v-if="user.newsletter_prefs.states.length == 0">All States</span>
        <span v-for="sc in user.newsletter_prefs.states" :key="sc">
            <span class="badge badge-xs badge-primary">{{sc}}</span>&nbsp;</span>

      </td>
    </tr>

  </table>
</div>


<div v-if="showEmployerTools || hasRole('employer') || hasRole('organization')" class="q-my-xl">
  <div class="row justify-between">
    <div class="text-h5">
      My Organizations
    </div>
    <div>
      <q-btn size="sm" color="blue" :to="{name: 'orgAdd'}">Add Organization</q-btn>
    </div>
  </div>

    <div v-if="user.organizations && user.organizations.length > 0">
          <table class="table table-sm table-striped full-width">
            <tr>
              <th style="width: 20px;"></th>
              <th>Name</th>
              <th style="width: 160px;">Relationship</th>
              <th style="width: 100px; text-align: right;"></th>
            </tr>
            <tr v-for="org in user.organizations" :key="org._id">
              <td style="width: 24px;">
                <router-link :to="{name: 'orgEdit', params: {orgId: org._id}}">
                  <q-icon name="fa fa-pencil" />
                </router-link>
              </td>
              <td>
                <router-link :to="{name: 'orgView', params: {orgId: org._id}}">
                  {{org.name}}
                </router-link>
              </td>
              <td>{{org.relationship}}</td>
              <td class="text-right">
                <q-btn size="sm" color="blue" unelevated to="/account/jobs/add">Add Post</q-btn>
              </td>
            </tr>
          </table>

    </div>

    <div v-if="! user.organizations || user.organizations.length == 0">
        There are no organizations associated with this account yet.
    </div>
</div>

<!-- jobseeker -->
<div v-if="showJobseekerTools || hasRole('jobseeker')" class="q-my-xl">

  <div class="row justify-between">
    <div class="text-h5">
      My Job Candidate Information &amp; Resume
    </div>
    <div>
      <q-btn v-if="hasRole('jobseeker')" size="sm" color="blue" to="/account/jobseeker/edit">Edit Candidate Info</q-btn>
      <q-btn v-if="! hasRole('jobseeker')" size="sm" color="blue" to="/account/jobseeker/edit">Add Job Candidate Info</q-btn>
    </div>
  </div>

    <div v-if="hasRole('jobseeker')">
        <q-banner v-if="! user.jobseeker_info.resume" rounded class="q-my-sm bg-red-6 text-white">
            There is no resume on this account yet.  You will need to upload a resume to apply for a job.

            <template v-slot:action>
              <q-btn flat color="whilte" @click="$refs.uploader.pickFiles($event)">Add Resume</q-btn>
            </template>
          </q-banner>

      <table class="table table-sm table-striped full-width">
        <tr>
          <th style="width: 180px;">Location</th>
          <td>
        {{user.jobseeker_info.location.city}},
        <span v-if="user.jobseeker_info.location.country_code == 'US'">{{user.jobseeker_info.location.stateCode}}
        {{user.jobseeker_info.location.zipcode}}
        <br /></span>
        {{user.jobseeker_info.location.country_code}}
          </td>
        </tr>

        <tr>
          <th>Preferred Role:</th>
          <td>
            {{user.jobseeker_info.title}}
          </td>
        </tr>

        <tr v-if="user.jobseeker_info.linkedin_url">
          <th>LinkedIn</th>
          <td><a :href="user.jobseeker_info.linkedin_url" target="_blank">{{user.jobseeker_info.linkedin_url}}</a></td>
        </tr>

        <tr>
          <th>Skills</th>
          <td class="col-12 q-gutter-sm">
            <q-badge v-for="skill in user.jobseeker_info.skills" :key="skill" color="accent">
              {{skill}}
            </q-badge>
            <span v-if="! user.jobseeker_info.skills || user.jobseeker_info.skills.length == 0">None listed</span>
          </td>
        </tr>


        <tr v-if="user.jobseeker_info.degrees">
          <th>Degrees</th>
          <td class="col-12 q-gutter-sm">
            <q-badge v-for="dg in user.jobseeker_info.degrees" :key="dg" color="accent">{{dg}}</q-badge>
            <span v-if="! user.jobseeker_info.degrees || user.jobseeker_info.degrees.length == 0">None listed</span>
          </td>
        </tr>

        <tr v-if="user.jobseeker_info.ed_history">
          <th>Education History</th>
          <td class="col-12 q-gutter-sm">
            <div v-for="(ed, idx) in user.jobseeker_info.ed_history" :key="idx" >
                {{ed.degree}}
                <span v-if="ed.degree && ed.field_of_study">in</span>
                {{ed.field_of_study}}

                <span v-if="ed.degree || ed.field_of_study">@</span>

                {{ed.school }}

                {{ed.start_date }}
                <span v-if="ed.start_date && ed.end_date">-</span>
                {{ed.end_date }}
            </div>

            <span v-if="! user.jobseeker_info.ed_history || user.jobseeker_info.ed_history.length == 0">None listed</span>
          </td>
        </tr>

        <tr v-if="user.jobseeker_info.exp_history">
          <th>Experience</th>
          <td class="col-12 q-gutter-sm">
            <div v-for="(exp, idx) in user.jobseeker_info.exp_history" :key="idx" >
                {{exp.title}}

                <span v-if="exp.company">@
                  {{exp.company}}
                </span>

                {{exp.start_date }}
                <span v-if="exp.start_date && exp.end_date">-</span>
                {{exp.end_date }}
            </div>

            <span v-if="! user.jobseeker_info.exp_history || user.jobseeker_info.exp_history.length == 0">None listed</span>
          </td>
        </tr>

        <tr>
          <th>Availability:</th>
          <td>
            <div class="row column">
              <div v-if="user.jobseeker_info.available" class="text-body2"><strong>I am</strong> currently available to take on work or be hired</div>
              <div v-else class="text-body2">I am <strong>not</strong> currently available to take on work or be hired</div>

              <div v-if="user.jobseeker_info.can_work_remote" class="text-body2">I can work remotely</div>

              <div v-if="user.jobseeker_info.salary && user.jobseeker_info.salary.amount" class="text-body2">I would work for {{user.jobseeker_info.salary.amount | currency }}
                  {{user.jobseeker_info.salary.pay_type }}</div>

              <div class="text-body2 q-gutter-x-sm">
                <q-badge v-for="ct in user.jobseeker_info.contract_types" :key="ct" color="accent">{{ct}}</q-badge>
                <span v-if="! user.jobseeker_info.contract_types || user.jobseeker_info.contract_types.length == 0">No contract types listed</span>
              </div>


            </div>
          </td>
        </tr>

        <tr>
          <th>Resume:</th>
          <td>
            <div class="row">
              <div class="col-7">
                <a v-if="user.jobseeker_info.resume" :href="'/resumes/' + user.jobseeker_info.resume.path"><span class="fa fa-file"></span> {{ user.jobseeker_info.resume.orig_filename }}</a>
              </div>
              <div class="col-5">
              </div>
            </div>

          </td>
        </tr>

        <tr>
          <td colspan="2">
            <div class="q-pa-md">
              <div class="q-py-sm text-weight-bold">
                To select your resume and upload it, click the "+" button on the right.
              </div>

              <q-uploader
              ref="uploader"
        label="Resume Uploader (pdf, doc, odt, rtf, txt; max size 2MB)"
        color="blue"
        text-color="white"
        class="full-width"
        auto-upload
        field-name="file"
        url="/api/account/jobseeker/resume/upload"
        accept=".pdf,.doc,.docx,.odt,.rtf,.txt"
        :max-file-size="5000000"
        flat square
        :multiple="false"
        :headers="[{name: 'Authorization', value: 'Bearer ' + token }]"
        @uploaded="uploadSuccess"
        @failed="uploadFailure"
      />
            </div>
          </td>
        </tr>

      </table>



    </div>
    <div v-else class="q-py-sm">
        <q-banner rounded class="bg-red-6 text-white">
            There is no job candidate information listed on this account yet.  You will need to set up your candidate profile, and upload a resume to apply for a job.

            <template v-slot:action>
              <q-btn flat color="whilte" to="/account/jobseeker/edit">Add Job Candidate Info</q-btn>
            </template>
          </q-banner>
    </div>

</div>

<!-- /jobseeker -->


<div class="q-my-lg q-gutter-sm">
  <q-btn color="blue" size="sm" icon="refresh" @click="refreshAccount">Refresh Account</q-btn>
  <q-btn v-if="hasRole('admin')" size="sm" color="negative" @click="stopAdmin()">Drop Admin</q-btn>
</div>

<div v-if="hasRole('admin')" class="bg-grey-3 q-pa-md">
  <q-btn v-if="hasRole('admin')" size="sm" color="primary" @click="fbLogin()">FB Login</q-btn>

<div class="q-my-lg q-gutter-sm flex items-start">
  <q-input v-model="fbOpts.path" dense label="Path" style="width: 200px;"></q-input>
  <q-btn v-if="hasRole('admin')" size="sm" color="primary" @click="getFBInfo()">Get FB Info</q-btn>
</div>
</div>

<div v-if="hasRole('admin')">
<pre style="background-color: #fcfcfc;">
{{user}}
</pre>
</div>

<!-- -->

</div>
</template>

<script>
// import { PostActionTypes } from "../../store/module-post/action-types";
import { mapState, mapGetters } from 'vuex'
import { AuthActionTypes } from 'src/modules/auth/store/action-types';
import ApiService from 'src/services/api.service';
import { notifySuccess, notifyError } from 'src/services/utils';

export default {
  data() {
    return {
      nl_loading: false,
      newFrequency: 2,
      fbOpts: {
        path: '/me',
        method: 'get',
        params: null
      },
      refreshingAccount: false,
      showEmployerTools: false,
      showJobseekerTools: false,
    };
  },
  computed: {
    ...mapState('auth', {
      token: 'token',
      // user: 'user',
    }),
    ...mapGetters('auth', {
      hasRole: 'hasRole',
    }),
    user() {
      return this.$store.state.auth.user;
    }
  },
  created () {
    if (this.hasRole('employer') || this.hasRole('organization') ) {
      this.showEmployerTools = true;
    }
    if (this.hasRole('jobseeker')) {
      this.showJobseekerTools = true;
    }

    if (process.env.FB_PIXEL) {
      window.fbq('trackCustom', 'AccountHome');
    }

    if (this.$route.query.error) {
      notifyError(this.$route.query.error, this.$route.query.errorMsg);
    }
  },
  methods: {
    refreshAccount() {
      this.refreshingAccount = true;
      this.$store.dispatch(`auth/${AuthActionTypes.AuthRefreshRequest}`);
    },
    fbLogin() {
      this.$fb.login( (res) => {
        console.log('FB AUTH res', res);
      });
    },
    getFBInfo() {
      this.$fb.api(this.fbOpts.path, this.fbOpts.method, this.fbOpts.params, (result) => {
        console.log('FB RESULT is ', result);
      })

    },
    uploadSuccess(info) {
      const fileData = JSON.parse(info.xhr.response);
      console.log('uploaded ', fileData);
      this.$store.dispatch(`auth/${AuthActionTypes.AuthSetField}`, {key: 'user.jobseeker_info.resume', value: fileData});
    },
    async uploadFailure(info) {
      // const fileData = JSON.parse(info.xhr.response);
      if (info.xhr.status == 401) {
        if (await this.$store.dispatch(`auth/${AuthActionTypes.AuthRefreshTokenRequest}`) ) {
          this.$refs.uploader.upload();
        } else {
          notifyError('Unauthorized', 'Uploading was rejected becuase your session failed, please log in again');
        }
        return;
      }

      notifyError('Uploading was rejected, please contact support');

    },

    async resendConfirmation() {
      console.log('resend');

      try {
        const apiData = await ApiService.get('/api/auth/resend_confirmation');
        console.log('got back ', apiData.data);
        notifySuccess(apiData.data.message);

      } catch (err) {
        console.log('cautght err', err);

        notifyError('Resend Verification Email', err);

        return false;
      } finally {
        this.loading = false;
      }

    },

    async setNLFreq() {
      console.log('set NL Frequency');

      try {
        const apiData = await ApiService.post('/api/account/set_nl_frequency', {limit: this.newFrequency});
        console.log('got back ', apiData.data);

        this.$store.dispatch(`auth/${AuthActionTypes.AuthGetUserSuccess}`, apiData.data)
        notifySuccess('Newsletter Preferences Updated');

      } catch (err) {
        console.log('cautght err', err);
        notifyError('Setting Newsletter Preference Failed', err);

        return false;
      } finally {
        this.nl_loading = false;
      }

    },
    stopAdmin() {
      this.$store.dispatch(`auth/${AuthActionTypes.AuthRemoveRole}`, 'admin')
    }
  },
}
</script>
