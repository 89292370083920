<template>
  <div>
    <q-linear-progress v-if="loading" color="secondary" indeterminate />
    <q-page padding>
      <router-view />
    </q-page>
  </div>
</template>
<style scoped>
.v-progress-linear {
  margin-top: 0;
}
.v-pagination__item {
  border: 0;
}
</style>
<script>
import { mapState } from 'vuex';
// TODO
// import { AccountOrderActionTypes } from '../../store/module-account-order/action-types';

export default {
  computed: {
    ...mapState('account', {
      loading: 'loading',
    })
  },
  created() {
    // if (! this.$store.state.accountOrder.loaded ) {
    //   this.$store.dispatch( 'accountOrder/' + AccountOrderActionTypes.AccountOrderLoadAllRequest );
    // }

  },
  methods: {
  },
}
</script>

