var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('q-breadcrumbs',[_c('q-breadcrumbs-el',{attrs:{"icon":"home","to":"/home"}}),_c('q-breadcrumbs-el',{attrs:{"to":"/account/home","label":"Account"}}),_c('q-breadcrumbs-el',{attrs:{"label":"Update Information"}})],1),_c('div',{staticClass:"q-my-lg"},[_c('div',{staticClass:"text-uppercase text-h5"},[_vm._v("\n  Update Name\n")]),_c('q-form',{ref:"changeNameForm",on:{"submit":_vm.onNameSubmit}},[_c('div',{staticClass:"q-my-lg row"},[_c('div',{staticClass:"col-12 col-sm-6"},[_c('q-input',{ref:"first_name",attrs:{"dense":"","name":"first_name","type":"text","label":"First Name","rules":[
              function (val) { return !!val || 'Field is required'; },
              function (val) { return val.length >= 2 || 'Field is too short'; },
              function (val) { return _vm.getServerError('first_name'); } ]},on:{"blur":function($event){return _vm.clearServerError('first_name')}},model:{value:(_vm.first_name),callback:function ($$v) {_vm.first_name=$$v},expression:"first_name"}}),_c('q-input',{ref:"last_name",attrs:{"dense":"","name":"last_name","type":"text","label":"Last Name","rules":[
              function (val) { return !!val || 'Field is required'; },
              function (val) { return val.length >= 2 || 'Field is too short'; },
              function (val) { return _vm.getServerError('last_name'); } ]},on:{"blur":function($event){return _vm.clearServerError('last_name')}},model:{value:(_vm.last_name),callback:function ($$v) {_vm.last_name=$$v},expression:"last_name"}}),_c('q-btn',{attrs:{"type":"submit","color":"primary","no-caps":"","loading":_vm.name_loading}},[_vm._v("Save Name")])],1)])])],1),_c('div',{staticClass:"q-my-lg"},[_c('div',{staticClass:"text-uppercase text-h5 q-mt-md"},[_vm._v("\n  Change Password\n")]),_c('q-form',{ref:"changePassForm",on:{"submit":_vm.onChpassSubmit}},[_c('div',{staticClass:"q-my-lg row"},[_c('div',{staticClass:"col-12 col-sm-6"},[_c('q-input',{ref:"oldPassField",attrs:{"dense":"","name":"cur_pass","type":_vm.isPwd ? 'password' : 'text',"label":"Old Password","rules":[
              function (val) { return !!val || 'Field is required'; },
              function (val) { return val.length >= 6 || 'Field is too short'; },
              function (val) { return _vm.getServerError('cur_pass'); } ]},on:{"blur":function($event){return _vm.clearServerError('cur_pass')}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('q-icon',{staticClass:"cursor-pointer",attrs:{"name":_vm.isPwd ? 'visibility_off' : 'visibility'},on:{"click":function($event){_vm.isPwd = !_vm.isPwd}}})]},proxy:true}]),model:{value:(_vm.oldPassword),callback:function ($$v) {_vm.oldPassword=$$v},expression:"oldPassword"}}),_c('q-input',{ref:"newPassField",attrs:{"dense":"","type":_vm.isPwd ? 'password' : 'text',"label":"New Password","name":"new_pass","rules":[
              function (val) { return !!val || 'Field is required'; },
              function (val) { return val.length >= 6 || 'Field is too short'; },
              function (val) { return _vm.getServerError('new_pass'); } ]},on:{"blur":function($event){return _vm.clearServerError('new_pass')}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('q-icon',{staticClass:"cursor-pointer",attrs:{"name":_vm.isPwd ? 'visibility_off' : 'visibility'},on:{"click":function($event){_vm.isPwd = !_vm.isPwd}}})]},proxy:true}]),model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}}),_c('q-input',{attrs:{"dense":"","type":_vm.isPwd ? 'password' : 'text',"label":"Confirm Password","name":"pass_confirm","rules":[
              function (val) { return !!val || 'Field is required'; },
              function (val) { return val.length >= 6 || 'Field is too short'; },
              function (val) { return val == _vm.newPassword || 'Field must martch password field'; },
              function (val) { return _vm.getServerError('pass_confirm'); } ]},on:{"blur":function($event){return _vm.clearServerError('pass_confirm')}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('q-icon',{staticClass:"cursor-pointer",attrs:{"name":_vm.isPwd ? 'visibility_off' : 'visibility'},on:{"click":function($event){_vm.isPwd = !_vm.isPwd}}})]},proxy:true}]),model:{value:(_vm.passwordCfm),callback:function ($$v) {_vm.passwordCfm=$$v},expression:"passwordCfm"}}),_c('q-btn',{attrs:{"type":"submit","color":"primary","no-caps":"","loading":_vm.pass_loading}},[_vm._v("Save Password")])],1)])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }