<template>
<div>
    <q-breadcrumbs>
      <q-breadcrumbs-el icon="home" to="/home" />
      <q-breadcrumbs-el to="/account/home" label="Account" />
      <q-breadcrumbs-el label="Update Information" />
    </q-breadcrumbs>



<div class="q-my-lg">
<div class="text-uppercase text-h5">
  Update Name
</div>

<q-form ref="changeNameForm" @submit="onNameSubmit">
<div class="q-my-lg row">
    <div class="col-12 col-sm-6">
      <q-input
          ref="first_name"
          v-model="first_name"
          dense
          name="first_name"
          type="text"
          label="First Name"
          :rules="[
              val => !!val || 'Field is required',
              val => val.length >= 2 || 'Field is too short',
              val => getServerError('first_name'),
          ]"
          @blur="clearServerError('first_name')"
        >
        </q-input>

      <q-input
          ref="last_name"
          v-model="last_name"
          dense
          name="last_name"
          type="text"
          label="Last Name"
          :rules="[
              val => !!val || 'Field is required',
              val => val.length >= 2 || 'Field is too short',
              val => getServerError('last_name'),
          ]"
          @blur="clearServerError('last_name')"
        >
        </q-input>

        <q-btn type="submit" color="primary" no-caps :loading="name_loading">Save Name</q-btn>
      </div>

</div>
</q-form>
</div>



<div class="q-my-lg">
<div class="text-uppercase text-h5 q-mt-md">
  Change Password
</div>

<q-form ref="changePassForm" @submit="onChpassSubmit">
<div class="q-my-lg row">
    <div class="col-12 col-sm-6">
      <q-input
          ref="oldPassField"
          v-model="oldPassword"
          dense
          name="cur_pass"
          :type="isPwd ? 'password' : 'text'"
          label="Old Password"
          :rules="[
              val => !!val || 'Field is required',
              val => val.length >= 6 || 'Field is too short',
              val => getServerError('cur_pass'),
          ]"
          @blur="clearServerError('cur_pass')"
        >
          <template v-slot:append>
            <q-icon :name="isPwd ? 'visibility_off' : 'visibility'" class="cursor-pointer" @click="isPwd = !isPwd" />
          </template>
        </q-input>

      <q-input
          ref="newPassField"
          v-model="newPassword"
          dense
          :type="isPwd ? 'password' : 'text'"
          label="New Password"
          name="new_pass"
          :rules="[
              val => !!val || 'Field is required',
              val => val.length >= 6 || 'Field is too short',
              val => getServerError('new_pass'),
          ]"
          @blur="clearServerError('new_pass')"
        >
          <template v-slot:append>
            <q-icon :name="isPwd ? 'visibility_off' : 'visibility'" class="cursor-pointer" @click="isPwd = !isPwd" />
          </template>
        </q-input>

        <q-input
          v-model="passwordCfm"
          dense
          :type="isPwd ? 'password' : 'text'"
          label="Confirm Password"
          name="pass_confirm"
          :rules="[
              val => !!val || 'Field is required',
              val => val.length >= 6 || 'Field is too short',
              val => val == newPassword || 'Field must martch password field',
              val => getServerError('pass_confirm'),
          ]"
          @blur="clearServerError('pass_confirm')"
        >
          <template v-slot:append>
            <q-icon :name="isPwd ? 'visibility_off' : 'visibility'" class="cursor-pointer" @click="isPwd = !isPwd" />
          </template>
        </q-input>

        <q-btn type="submit" color="primary" no-caps :loading="pass_loading">Save Password</q-btn>
      </div>

</div>
</q-form>
</div>



</div>
</template>

<script>
import { mapState } from 'vuex'
import { Notify } from 'quasar';
import { serverErrorMixin } from "src/mixins/server_error.mixin";
import { ApiService } from "src/services/api.service";
import { AuthActionTypes } from "src/modules/auth/store/action-types";
import { TokenService } from "src/services/storage.service"
import { notifyError } from 'src/services/utils';

export default {
  mixins: [serverErrorMixin],
  data() {
    return {
      refreshingAccount: false,
      oldPassword: null,
      newPassword: null,
      passwordCfm: null,

      first_name: null,
      last_name: null,
      isPwd: true,
      pass_loading: false,
      name_loading: false,
    };
  },
  computed: {
    ...mapState('auth', {
      // user: 'user',
    }),
    user() {
      return this.$store.state.auth.user;
    }
  },
  created () {
    this.first_name = this.user.first_name;
    this.last_name = this.user.last_name;
  },
  methods: {
    async onChpassSubmit() {
      this.pass_loading = true;

      try {
        const apiData = await ApiService.post(`/api/account/change_pass`, {
          cur_pass: this.oldPassword,
          new_pass: this.newPassword,
          pass_confirm: this.passwordCfm,
        });

        // this.$store.dispatch(`auth/${AuthActionTypes.AuthLoginSuccess}`, apiData.data);
        Notify.create({
          position: 'top-right',
          color: 'green',
          icon: 'check',
          timeout: 2500,
          message: apiData.data.message,
          actions: [
            { icon: 'close', color: 'white' },
          ],
        });

        this.$router.push({name: 'accountHome'});

        return true;
      } catch (err) {
        if (err.response) {
          this.serverErrorMessage = err.response.data.message;

          if (err.response.data.errors) {
            this.serverErrors = err.response.data.errors;
          } else {
            console.log('no serverErrors');
          }
          this.$refs.changePassForm.validate();
        } else {
          console.log("Error: ", err.message);
          throw err;
        }

        return false;
      } finally {
        this.pass_loading = false;
      }
    },
    async onNameSubmit() {
      this.name_loading = true;

      try {
        const apiData = await ApiService.post(`/api/account/change_name`, {
          first_name: this.first_name,
          last_name: this.last_name,
        });

        TokenService.saveToken(apiData.data.access_token);
        ApiService.setHeader();

        // dispatch
        this.$store.dispatch(`auth/${AuthActionTypes.AuthLoginSuccess}`, apiData.data);


        Notify.create({
          position: 'top-right',
          color: 'green',
          icon: 'check',
          timeout: 2500,
          message: apiData.data.message,
          actions: [
            { icon: 'close', color: 'white' },
          ],
        });

        this.$router.push({name: 'accountHome'});

        return true;
      } catch (err) {
        if (err.response) {

          this.serverErrorMessage = err.response.data.message;
          this.processErrorData(err.response.data)

          // notifyError('Update Name', err);

          this.$refs.changeNameForm.validate();
        } else {
          notifyError('Update Name', err);

          console.log("Error: ", err.message);
          throw err;
        }

        return false;
      } finally {
        this.name_loading = false;
      }
    },
  },
}
</script>
